import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <Link
            onClick={() => {
              window.open("https://app.dappos.io/PrivacyPolicy");
            }}
          >
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link
            onClick={() => {
              window.open("https://app.dappos.io/TermsOfService");
            }}
          >
            Terms of Service
          </Link>
        </li>
        <li>
          <Link
            onClick={() => {
              window.open("https://discord.com/invite/V2TmH8BBPy");
            }}
          >
            Support
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
