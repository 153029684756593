import React from "react";
import "./stickyBar.css";

const StickyBar = () => {
  return (
    <div className="sticky-bar">
      Dappos Remix IDE plugin available now.
      <a
        href="https://documentation.dappos.io/remix-ide-plugin/intro"
        target="_blank"
        rel="noopener noreferrer"
        className="sticky-bar-link"
      >
        Try it out here!
      </a>
    </div>
  );
};

export default StickyBar;
